exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-corporate-communications-training-js": () => import("./../../../src/pages/services/corporate-communications-training.js" /* webpackChunkName: "component---src-pages-services-corporate-communications-training-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-market-research-and-consultancy-js": () => import("./../../../src/pages/services/market-research-and-consultancy.js" /* webpackChunkName: "component---src-pages-services-market-research-and-consultancy-js" */),
  "component---src-pages-services-translation-and-localization-js": () => import("./../../../src/pages/services/translation-and-localization.js" /* webpackChunkName: "component---src-pages-services-translation-and-localization-js" */)
}

